<template>
  <v-app id="inspire">
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      Senha alterada com sucesso, redirecionando para login. <v-icon color="green">mdi-check-bold</v-icon>
    </v-snackbar>
    <v-app id="inspire">
      <v-main>
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title><v-icon>mdi-account</v-icon> Informe sua senha</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="valid" ref="form">
                    <v-text-field
                      label="Senha"
                      name="password"
                      prepend-icon="mdi-lock-outline"
                      type="password"
                      v-model="$store.state.user.password"
                      :rules="validPassword"
                    ></v-text-field>

                    <v-text-field
                      label="Confirma a senha"
                      name="confirmPassword"
                      prepend-icon="mdi-lock-outline"
                      type="password"
                      v-model="$store.state.user.confirmPassword"
                      :rules="validConfirm"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <v-btn color="primary" :loading="loading" @click="send"
                    >Enviar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </v-app>
</template>

<script>
import { alterPassword } from "../../services/users";
//import { forgotPassword } from '../../services/users'
export default {
  data: () => ({
    timeout: 3000,
    snackbar: false,
    loading: false,
    valid: false,
    message: null,
    rulePassword: [true],
    validPassword: [],
    validConfirm: [],
  }),
  methods: {
    async send() {
      this.loading = true;
      this.$refs.form.validate();
      if (!this.valid) return;
      await alterPassword(
        this.$store.state.user.password,
        this.$store.state.user.confirmPassword
      );
      this.snackbar = true
      setTimeout(() => {
        this.loading = false;
        this.$store.state.user.password = "";
        this.$store.state.user.confirmPassword = "";
        this.$router.push("/login");
      }, this.timeout);

      // forgotPassword(this.$store.state.user.email)
      // .then((data) => {
      //   this.message = data
      // })
      // .catch(err => {
      //   this.message = err
      // })
    },
  },
  watch: {
    "$store.state.user.confirmPassword"() {
      this.$refs.form.validate();
    },
  },
  mounted() {
    localStorage.setItem("token", this.$route.params.token);
    this.validPassword = [
      (value) =>
        value.length >= 8 || "Senha tem que ter no mínimo 8 caracteres",
      (value) =>
        value == this.$store.state.user.confirmPassword ||
        "Senhas não são iguais",
    ];
    this.validConfirm = [
      (value) =>
        value.length >= 8 || "Senha tem que ter no mínimo 8 caracteres",
      (value) =>
        value == this.$store.state.user.password || "Senhas não são iguais",
    ];
  },
};
</script>

<style lang="scss" scoped>
</style>